<template>
  <!-- BOSS -->
  <div class="boss" id="BOSS">
    <div class="container">
      <!-- 左边区域 -->
      <div class="boss-left" :class="{ active: active }">
        <div class="boss-left-container">
          <!-- 展开/收缩 -->
          <div class="top" @click="toggle">
            <i class="iconfont icon-xiangyou1"></i>
          </div>
          <!-- 导航 -->
          <ul class="boss-left-nav">
            <li :class="{ active: BossIndex === 1 }" @click.prevent="PacMan">
              <a href="javascript:">
                <img src="../assets/image/boss01.png" alt="" />
                <span> 吃豆人</span>
              </a>
            </li>
            <li :class="{ active: BossIndex === 2 }" @click.prevent="Bobble">
              <a href="javascript:">
                <img src="../assets/image/boss02.png" alt="" />
                <span> 泡泡龙</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss03.png" alt="" />
                <span> 炸弹人</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss04.png" alt="" />
                <span> 库巴</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss05.png" alt="" />
                <span> 马戏团</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss06.png" alt="" />
                <span> 波波</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss07.png" alt="" />
                <span> 坦克</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss08.png" alt="" />
                <span> 梅塔</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss09.png" alt="" />
                <span> 气球战士</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss10.png" alt="" />
                <span> 快乐鼠</span>
              </a>
            </li>
            <li>
              <a href="javascript:">
                <img src="../assets/image/boss11.png" alt="" />
                <span> 星之卡比</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- 右边区域 -->
      <div class="boss-right">
        <div class="content">
          <!-- 标题 -->
          <h2 class="h2 section-title">BOSS说明书</h2>

          <!-- 技能分类 -->
          <div class="skill">
            <ul>
              <li
                v-for="(item, index) in BossData"
                :key="index"
                :class="{ active: skillIndex === index }"
                @click="skillIndex = index"
              >
                {{ item.Name }}
              </li>
            </ul>
          </div>

          <!-- 技能演示 -->
          <div class="skill-body">
            <!-- 技能说明 -->
            <div class="skill-explain">
              <div class="body">
                <h3
                  v-html="
                    '当前难度描述：' + BossData[0].skill[difficulty].title
                  "
                ></h3>
                <p class="h">温馨提示：点击右边图片可以切换难度描述文本！</p>
                <br />
                <p
                  v-html="BossData[skillIndex].skill[difficulty].description"
                ></p>
              </div>
            </div>

            <!-- 技能演示 -->
            <div class="skill-gif">
              <ul>
                <li
                  v-for="(item, index) in BossData[skillIndex].skill"
                  :key="index"
                  @click="difficulty = index"
                >
                  <h4 v-html="item.title"></h4>

                  <img :src="item.gif" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PacMan, Bobble } from "../utils/BossData.js"; // BOSS数据
export default {
  // 数据
  data() {
    return {
      // BOSS数据
      BossData: PacMan,
      // 技能索引
      skillIndex: 0,
      // 当前boss索引
      BossIndex: 1,
      // 当前难度索引
      difficulty: 0,
      // 显示或隐藏导航栏
      active: false,
    };
  },
  // 挂载阶段(渲染模版)
  mounted() {
    console.log(this.BossData);
  },

  // 逻辑处理
  methods: {
    // 吃豆人
    PacMan() {
      this.BossData = PacMan;
      this.BossIndex = 1;
    },
    // 泡泡龙
    Bobble() {
      this.BossData = Bobble;
      this.BossIndex = 2;
    },
    // 显示或隐藏侧边导航栏
    toggle() {
      this.active = !this.active;
    },
  },
};
</script>

<style scoped>
.boss {
  width: 100%;
  height: 100%;
  /* background: #051d29; */
  margin-top: 96px;
}

/* 容器 */
.boss .container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  background: var(--raisin-black-1);
  padding: 0;
}

/* 侧导航 ---------------------------------------------------------------------------*/
.boss .boss-left {
  background: var(--raisin-black-3);
  padding: auto 0;
  transition: all 0.5s;
}
/* 侧导航容器 */
.boss-left-container {
  width: 178px;
  height: 100%;
  transition: all 0.5s;
}

/* 字体图标 */
.boss-left-container .top {
  text-align: center;
  line-height: 100px;
  cursor: pointer;
  transition: all 0.5s;
}
/* 字体图标 */
.boss-left-container .top i {
  font-size: 30px;
  color: #7e807d;
  transition: all 0.3s;
}
/* 字体图标 */
.boss-left-container .top:hover i {
  color: #fff;
}
/* 字体图标 */
.boss-left.active .boss-left-container .top {
  transform: rotate(180deg);
}

/* 侧导航  ul */
.boss-left-nav {
  display: flex;
  height: 50%;
  flex-direction: column;
  transition: all 0.3s;
}

.boss-left-nav li {
  min-height: 50px;
  line-height: 50px;
}

.boss .boss-left li a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  font-size: 18px;
  color: #7e807d;
  transition: all 0.5s;
}

.boss-left-nav li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* 侧导航 切换 */
.boss-left.active .boss-left-container {
  width: 100px;
}

.boss-left.active .boss-left-nav li a {
  justify-content: center;
  padding-left: 0 !important;
}

.boss-left.active .boss-left-nav li span {
  display: none;
}
.boss-left.active .boss-left-nav li img {
  margin-right: 0px;
}

.boss .boss-left li:hover a,
.boss .boss-left li.active a {
  color: #fff;
  font-weight: 900;
  border-left: 3px solid #13ffff;
  background-image: linear-gradient(
    90deg,
    rgba(19, 255, 255, 0.05),
    rgba(19, 255, 255, 0)
  );
  transition: all 0.3s;
}

/* ------------------------------------------------------------------------------------ */

/* 右边区域 -----------------------------------------------------------------------------*/
.boss .boss-right {
  flex-grow: 1;
  padding-top: 20px;
}

/* 右边区域 内容 */
.boss .boss-right .content {
  min-width: 1500px;
}

/* 右边区域  标题 */
.boss .boss-right h2 {
  margin-bottom: 60px;
}

/* 技能选择按钮 */
.boss .boss-right .skill ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-bottom: 60px;
}
/* 技能选择按钮 */
.boss .boss-right .skill li {
  width: 170px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: var(--onyx);
  color: #fff;
  cursor: pointer;
  border-radius: 30px 0 30px 0;
}
/* 技能选择按钮 */
.boss .boss-right .skill li:hover,
.boss .boss-right .skill li.active {
  background: var(--orange);
}

/* 技能说明 */
.boss .boss-right .skill-body {
  display: flex;
  width: 100%;
}
/* 技能说明 */
.boss .boss-right .skill-explain {
  width: 40%;
  padding-left: 200px;
  color: #b59758;
}

/* 技能演示 */
.boss .boss-right .skill-gif {
  width: 45%;
  height: 100%;
  margin-left: 50px;
}
/* 技能演示 */
.boss .boss-right .skill-gif ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
/* 技能演示 */
.boss .boss-right .skill-gif li {
  width: 210px;
  text-align: center;
  cursor: pointer;
}
/* 技能演示 */
.boss .boss-right .skill-gif img {
  width: 100%;
  height: 130px;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #13ffff;
}
.boss .boss-right .skill-gif li:nth-child(1):hover img {
  box-shadow: 0px 0px 30px #ffff00;
}
.boss .boss-right .skill-gif li:nth-child(2):hover img {
  box-shadow: 0px 0px 30px #00ff00;
}
.boss .boss-right .skill-gif li:nth-child(3):hover img {
  box-shadow: 0px 0px 30px #ff0000;
}
.boss .boss-right .skill-gif li:nth-child(4):hover img {
  box-shadow: 0px 0px 30px #aa0000;
}
.boss .boss-right .skill-gif li:nth-child(5):hover img {
  box-shadow: 0px 0px 30px #2222ff;
}
.boss .boss-right .skill-gif li:nth-child(6):hover img {
  box-shadow: 0px 0px 30px #f700ff;
}

/* ------------------------------------------------------------------------------------ */

/* 遮罩层 */
.overlay {
  position: fixed;
  /* inset 为简写属性，对应于 top、right、bottom 和 left 属性 */
  inset: 0;
  /* pointer-events CSS 属性指定在什么情况下 (如果有) 某个特定的图形元素可以成为鼠标事件的 target */
  pointer-events: none;
  z-index: 1;
  transition: var(--transition-2);
}

/* 遮罩层显示 */
.overlay.active {
  background: hsla(0, 0%, 0%, 0.7);
  pointer-events: all;
  transition: var(--transition-3);
}

option {
  /* 增加上下内边距来增加选项间的垂直间距 */
  padding: 5px 0;
}

/* 响应小于于1024px */
@media (max-width: 1024px) {
  .boss {
    margin-top: 69px;
  }
}

/* 响应大于1200px */
@media (min-width: 1200px) {
}
</style>