// 文件核心作用: 导入App.vue, 基于App.vue创建结构渲染index.html

import Vue from 'vue'   // 导入 vue 核心包
import App from './App.vue'   // 导入 App.vue 根组件
import "../style/bootstrap.css";
import '../style/base.css'    // 导入基础样式
import '../style/common.css'  // 导入公共样式
import './assets/iconfont/iconfont.css'   // 导入字体图标


// let currentDateInBeijing = new Date( new Date().getTime() - new Date().getTimezoneOffset() * 60000 + 8 * 360000);
// console.log(currentDateInBeijing);

// 提示: 当前处于什么环境(生产环境 / 开发环境)
Vue.config.productionTip = false

// Vue实例化, 提供render方法 => 基于App.vue创建结构渲染index.html
new Vue({
  // el:'#app', 作用: 和$mount('选择器')作用一致,用于指定Vue所管理容器
  // 基于App创建元素结构
  render: h => h(App),
}).$mount('#app')
