// 吃豆人数据
export const PacMan = [

    // 随机方向 / 冲刺
    {
        Name: '随机方向 / 冲刺',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '随机50%概率选择一个方向（左或右）<br> 释放前摇：1.5秒 <br> 移动速度：30 <br> 走路次数：2 <br> 走出去后再次出现的位置纯随机 <br> ',
                gif: './gif/Pac-Man/BossSkill-1-E.gif'

            },
            {
                title: '<span class="n">NORMAL</span>',
                description: '<p>和<span class="e">简单难度</span>一样</p>',
                gif: './gif/null.png'
            },
            {
                title: '<span class="h">HARD</span>',
                description: '释放前摇：1.5秒 > 1.0秒',
                gif: './gif/null.png'
            },
            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '移动速度：30→256<br>走路次数：2→(5~8)<br>新增：吃豆人碰撞体积修正0>64(0.5格)实际碰撞64+96=160(1.25格)<br>',
                gif: './gif/Pac-Man/BossSkill-1-I.gif'
            },
            {
                title: '<span class="j">JOKE</span>',
                description: '<p>和<span class="i">不可能难度</span>一样</p>',
                gif: './gif/null.png'
            },
            {
                title: '<span class="k">KAIZO</span>',
                description: '<p>和<span class="i">不可能难度</span>一样</p>',
                gif: './gif/null.png'
            },
        ],
        resistance: [
            {
                ShieldP: '0%',
                ShieldM: '0%',
            },
            {
                ShieldP: '30%',
                ShieldM: '30%',
            },
            {
                ShieldP: '40%',
                ShieldM: '40%',
            },
            {
                ShieldP: '45%',
                ShieldM: '45%',
            },
            {
                ShieldP: '50%',
                ShieldM: '50%',
            },
            {
                ShieldP: '60%',
                ShieldM: '60%',
            },
        ],

    },

    // 弹幕技能 / 吐豆子
    {
        Name: '弹幕技能 / 吐豆子',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: ' 如果在第三层，则固定向上喷吐。<br>否则，寻找一个"随机目标"为朝向进行喷吐。<br><br>释放前摇：1.5秒<br>喷吐间隔：0.1秒<br>喷吐弹幕：2个<br>喷吐次数：30次<br>总持续时间：3秒<br><br>弹幕发射时以朝向点左右随机偏移60度。<br>弹幕伤害：0.5<br>弹幕速度：10~30<br>弹幕重力：-0.5<br> ',
                gif: './gif/Pac-Man/BossSkill-2-E.gif'
            },
            {
                title: '<span class="n">NORMAL</span>',
                description: '释放前摇：1.5秒 → 1.0秒<br><br>第一个喷吐的弹幕变化了↓<br>弹幕伤害：0.5 → 1.0<br>弹幕速度：(10~30)→(20~40)<br> 弹幕体积：1 → 1.5<br><br> 弹幕碰撞大小：0 → 128(1格)',
                gif: './gif/Pac-Man/BossSkill-2-N.gif'
            },
            {
                title: '<span class="h">HARD</span>',
                description: '和<span class="n">普通难度</span>一样',
                gif: './gif/null.png'
            },
            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '喷吐次数：2(一大一小弹幕) <br>弹幕碰撞类型：无视碰撞<br>弹幕寿命：无限 → 4秒<br>',
                gif: './gif/Pac-Man/BossSkill-2-I.gif'
            },
            {
                title: '<span class="j">JOKE</span>',
                description: '喷吐间隔：0.1秒→0.08秒(总持续时间3秒>2.4秒)',
                gif: './gif/null.png'
            },
            {
                title: '<span class="k">KAIZO</span>',
                description: '附加<span data-title="下一次受伤就会立即死亡">弹幕诅咒</span>：15秒',
                gif: './gif/Pac-Man/BossSkill-2-K.gif'
            },
        ],
    },

    // 锁定技能 / 咬人
    {
        Name: '锁定技能 / 咬人',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '寻找"随机目标"来进行攻击。<br>每次咬完一次都会寻找一个新的"随机目标"来进行攻击。<br>锁定敌人后，警示线跟随目标走动，直到完全锁定。<br><br>第一次锁定时间：1秒<br>锁定后移动延迟：0.2秒<br>锁定后移动速度：256<br>寻找下一个目标耗时：0.5秒<br>后续咬人次数：2次(总共3次)<br>后续摇人锁定时间：1秒-0.32*次数<br>',
                gif: './gif/Pac-Man/BossSkill-3-E.gif'
            },

            {
                title: '<span class="n">NORMAL</span>',
                description: '后续咬人次数：2次(总共3次) → 4次(总共5次)后续咬人锁定时间：如果后续咬人次数>2时，为0.36秒~0.68秒',
                gif: './gif/null.png'
            },

            {
                title: '<span class="h">HARD</span>',
                description: ' 和<span class="n">普通难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: ' 寻找下一个目标耗时：0.5秒→0秒<br>后续咬人次数>2时锁定时间：(0.36秒~0.68秒)→(0.20秒~0.68秒)<br>',
                gif: './gif/null.png'
            },

            {
                title: '<span class="j">JOKE</span>',
                description: '和<span class="i">不可能难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: '和<span class="i">不可能难度</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 范围技能 / 激光
    {
        Name: '范围技能 / 激光',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '射出激光来伤害玩家，击中后造成伤害并且击退目标。<br> 充能时间：1.5秒<br> 发射时间：0.5秒<br> 激光数量：1<br> 伤害：1<br> 后摇：1秒~2秒<br>',
                gif: './gif/Pac-Man/BossSkill-4-E.gif'
            },

            {
                title: '<span class="n">NORMAL</span>',
                description: '充能时间：1.5秒→1.2秒(总时间2.0秒→1.7秒)',
                gif: './gif/null.png'
            },

            {
                title: '<span class="h">HARD</span>',
                description: '激光数量：1 → 3<br>伤害：1 → 2<br>',
                gif: './gif/Pac-Man/BossSkill-4-H.gif'
            },

            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: ' 和<span class="h">困难难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="j">JOKE</span>',
                description: ' 激光数量：3 → 5 <br>新增：激光附带晕眩效果<br>',
                gif: './gif/Pac-Man/BossSkill-4-J.gif'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: ' 和<span class="j">笑话难度</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 弹幕技能 / 鬼魂
    {
        Name: '弹幕技能 / 鬼魂',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '只有<span class="n">普通难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },

            {
                title: '<span class="n">NORMAL</span>',
                description: '召唤鬼魂在随机地点撞击玩家。<br> <br> 随机刷新向左或向右移动的鬼魂，总计15个。<br> <br> 释放前摇：0.5秒<br> 鬼魂凝聚时间：1.5秒→2.0秒<br> 弹幕寿命：2.5秒<br> 弹幕加速度：0.3~1.0<br> 弹幕碰撞大小：64(0.5格)<br> 弹幕碰撞类型：无视地形<br>',
                gif: './gif/Pac-Man/BossSkill-5-N.gif'
            },

            {
                title: '<span class="h">HARD</span>',
                description: ' 新增：上下移动的鬼魂，总计30个。',
                gif: './gif/Pac-Man/BossSkill-5-H.gif'
            },

            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: ' 所有鬼魂数量翻倍，左右移动15→30，上下移动30→60。',
                gif: './gif/null.png'
            },

            {
                title: '<span class="j">JOKE</span>',
                description: ' 和<span class="i">不可能难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: ' 和<span class="i">不可能难度</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 特殊技能 / 吃豆人
    {
        Name: '特殊技能 / 吃豆人',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="n">NORMAL</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="h">HARD</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="j">JOKE</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: '随机50%概率选择一个方向（左或右）<br> 释放前摇：6.5秒 <br> 移动速度：30 <br> 走路次数：2 <br> 走出去后再次出现的位置纯随机 <br> ',
                gif: './gif/Pac-Man/BossSkill-6-K.gif'
            },
        ],

    },

    // 终极技能 / 奥义
    {
        Name: '终极技能 / 奥义',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '破坏墙壁生成随机台阶，根据难度不同台阶也不同，并通向另一个房间，之后吃豆人会追逐你，直到完全抵达另外一个房间<br>如果难度大于或等于<span class="j">笑话难度</span>，则会在追逐时产生幽魂。<br><br>幽魂具有与以下属性：<br>弹幕碰撞类型：无视地形<br>弹幕寿命：3.0<br>弹幕加速度：（0.1~0.2）<br>弹幕碰撞大小：64(半格)<br>',
                gif: './gif/Pac-Man/BossSkill-7.png'
            },
        ],

    },
]


// 泡泡龙数据
export const Bobble = [

    // 弹幕技能 / 吐泡泡
    {
        Name: '弹幕技能 / 吐泡泡',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '泡泡龙上升到一定高度发射弹幕。<br>速度为50，每0.02秒衰减3，直到为0。<br>(48-3)/3+1=16<br>耗时≈16*0.02(0.32秒)<br><br>到达目标高度后，等待0.4秒并发射弹幕。<br>每次以随机角度为起始均匀发射，因此没有固定安全点。<br>发射完弹幕后以0.02秒3加速度逐渐下落，直到碰到地板。<br><br>发射耗时：0.2秒<br>发射次数：4次<br>弹幕速度：15<br>发射弹幕数量：6个弹幕伤害：0.5<br>弹幕碰撞大小：64(0.5格)<br>弹幕碰撞类型：计算地形<br>',
                gif: './gif/Bobble/BossSkill-1-E.gif'
            },
            {
                title: '<span class="n">NORMAL</span>',
                description: '发射弹幕数量：6个→10个',
                gif: './gif/null.png'
            },
            {
                title: '<span class="h">HARD</span>',
                description: '弹幕速度：15 → 17.5',
                gif: './gif/null.png'
            },
            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: ' 从发射泡泡变更为发射闪电，但弹幕数量有所减少。<br>发射次数：4次 → 6次<br>弹幕数量：10个 → 6个<br>弹幕碰撞类型：无视地形<br>弹幕寿命：无限→4秒<br>弹幕速度：17.5<br>弹幕加速度：0→0.5<br>弹幕伤害：0.5→1<br>',
                gif: './gif/Bobble/BossSkill-1-I.gif'
            },
            {
                title: '<span class="j">JOKE</span>',
                description: '弹幕数量：6个 → 10个',
                gif: './gif/Bobble/BossSkill-1-J.gif'
            },
            {
                title: '<span class="k">KAIZO</span>',
                description: '<p>和<span class="j">笑话难度</span>一样</p>',
                gif: './gif/null.png'
            },
        ],
        resistance: [
            {
                ShieldP: '0%',
                ShieldM: '50%',
            },
            {
                ShieldP: '40%',
                ShieldM: '60%',
            },
            {
                ShieldP: '45%',
                ShieldM: '65%',
            },
            {
                ShieldP: '40%',
                ShieldM: '85%',
            },
            {
                ShieldP: '40%',
                ShieldM: '85%',
            },
            {
                ShieldP: '50%',
                ShieldM: '90%',
            },
        ]
    },

    //  弹幕技能 / 向前吐泡泡
    {
        Name: ' 弹幕技能 / 向前吐泡泡',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '走路并发射弹幕。<br>如果泡泡龙正在2楼，则移动速度为原来的75%。<br>到达弹簧或前方没有路时则会回头。<br>释放前摇：1秒<br>释放后摇：1秒<br>移动速度：8<br>移动时间：5.1秒<br>发射弹幕间隔：0.5秒<br>向前方64(0.5格)发射3个泡泡，每新生成一个泡泡，发射角度都会上升15。<br><br>弹幕↓<br>弹幕速度：20~25<br>弹幕伤害：0.5<br>弹幕重力：0.75<br>弹幕体积：64(0.5格)<br></br>',
                gif: './gif/Bobble/BossSkill-2-E.gif'
            },
            {
                title: '<span class="n">NORMAL</span>',
                description: '移动速度：8 → 10<br>发射弹幕间隔：0.5秒 → 0.4秒<br>',
                gif: './gif/null.png'
            },
            {
                title: '<span class="h">HARD</span>',
                description: '移动速度：10 → 12',
                gif: './gif/null.png'
            },
            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '移动速度：12→15<br>发射弹幕间隔：0.4秒→0.2秒<br>',
                gif: './gif/null.png'
            },
            {
                title: '<span class="j">JOKE</span>',
                description: '现在走路期间可能会回头，回头前泡泡龙头上会有感叹号提示，只有在移动期间4秒才有可能回头。<br>回头判定时间：0.5秒<br>感叹号到回头时间：0.5秒<br>回头率：33%<br>',
                gif: './gif/Bobble/BossSkill-2-J.gif'
            },
            {
                title: '<span class="k">KAIZO</span>',
                description: '和<span class="j">笑话难度</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 锁定技能 / 践踏
    {
        Name: '锁定技能 / 践踏',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '泡泡龙上升到高空，一段时间后选择"随机目标"为地点下落，并发射泡泡。下落地点不会在弹簧上。<br>技能过程中泡泡龙无敌和无视碰撞。<br>会在落点显示警示线。<br><br>基础速度：8<br>加速度：8<br>上升时间：1秒<br>上升完毕等待寻找随机目标：0秒~0.75秒<br>落点以目标为中心X轴偏移：-128~128(左1格~右1格)<br>下落等待时间：1.5秒<br><br>下落时，泡泡龙从目标Y+2048(向上16格)的距离向下移动，每时刻向下移动256(2格)，共位移7个时刻。<br>每次个时刻造成一次践踏伤害<br>下落践踏宽度：224(1.75格)<br>下落践踏长度：256(2格)<br><br>落地践踏宽度：384(3格)<br>落地践踏长度：256(2格)<br><br>践踏击退加速度：64<br><br>践踏伤害：1<br>落地后向随机朝向(左右)发射15个泡泡，具有相同的随机速度。<br>泡泡随机速度：0~20<br>弹幕伤害：0.5<br>弹幕碰撞：64(0.5格)<br>弹幕重力：0.3<br><br>释放后摇：1.5秒<br></br>',
                gif: './gif/Bobble/BossSkill-3-E.gif'
            },

            {
                title: '<span class="n">NORMAL</span>',
                description: '下落等待时间：1.5秒 → 1秒<br>释放后摇：1.5秒 → 1秒 < br > ',
                gif: './gif/null.png'
            },

            {
                title: '<span class="h">HARD</span>',
                description: '不再显示下落警示线，践踏新增晕眩效果。<br>晕眩效果：2.5秒<br>践踏伤害：1 → 1.5<br>',
                gif: './gif/null.png'
            },

            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '泡泡随机下坠速度：0~20 → 0~30',
                gif: './gif/null.png'
            },

            {
                title: '<span class="j">JOKE</span>',
                description: '现在有50%概率再进行一次跳跃。<br>',
                gif: './gif/Bobble/BossSkill-3-J.gif'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: '和<span class="j">笑话难度</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 弹幕技能 / 头顶弹幕
    {
        Name: '弹幕技能 / 头顶弹幕',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '泡泡龙持续在头顶生成鱼弹幕，等待一定时间后，泡泡龙升天并将鱼发射。<br>释放前摇：1.0秒<br>弹幕生成间隔：0.1秒<br>生成弹幕数量：14个(1.4秒)<br>弹幕Y轴偏移：泡泡龙上方512(4格)<br>弹幕XY随机偏移：128(1格)<br>等待时间：0.5秒<br><br>上升：<br>速度为50，每0.02秒衰减3，直到小于0。<br>(51-3)/3+1=17<br>耗时≈17*0.02(0.34秒)<br><br>弹幕：<br>弹幕重力：-0.8<br>弹幕朝向：0~360<br>弹幕速度：15~30<br>弹幕伤害：0.5<br>弹幕寿命：4.0秒<br>弹幕碰撞类型：无视地形<br>弹幕碰撞大小：64(0.5格)<br><br>弹幕发射完毕后以-3加速度降落(0.34秒)<br>释放后摇：1.0秒<br>总耗时：1.0+0.1*14+0.5+0.34*2+1(4.58秒)<br></br>',
                gif: './gif/Bobble/BossSkill-4-E.gif'
            },

            {
                title: '<span class="n">NORMAL</span>',
                description: '有50%概率创建闪电而不是鱼。<br><br>闪电弹幕：<br>弹幕加速度：0.5~1.5<br>弹幕角度：0~360<br>弹幕速度：5<br>弹幕伤害：1<br>弹幕寿命：4.0秒<br>弹幕碰撞类型：无视地形<br>弹幕碰撞大小：64(0.5格)<br>',
                gif: './gif/Bobble/BossSkill-4-N.gif'
            },

            {
                title: '<span class="h">HARD</span>',
                description: ' 和<span class="n">普通难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '泡泡龙在生成完弹幕后不再等待直接上升去发射。<br> 弹幕生成间隔：0.1秒→0.08秒<br> 总时间≈1.0+0.08*14+0.34*2+1.0(3.8秒)<br>',
                gif: './gif/null.png'
            },

            {
                title: '<span class="j">JOKE</span>',
                description: '和<span class="i">不可能</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: '和<span class="i">不可能</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 锁定技能 / 跳跃
    {

        Name: '锁定技能 / 跳跃',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '寻找"随机目标"的位置来作为目标点进行跳跃，不会跳跃到弹簧上。<br>如果站在2楼台阶边缘(0.25格)之内，泡泡龙则会以1楼为落点而不是2楼。<br>当泡泡龙从2楼往1楼跳跃时，下落速度会更快，而从1楼往2楼跳跃时，上升速度会更快。<br><br>X偏移：-128(1格)~128(1格)<br>跳跃刷新时刻：0.02<br>跳跃时刻：50(49)次 在第50次结算<br>释放后摇：0.5<br>',
                gif: './gif/Bobble/BossSkill-5-E.gif'
            },

            {
                title: '<span class="n">NORMAL</span>',
                description: '和<span class="e">简单难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="h">HARD</span>',
                description: ' 和<span class="e">简单难度</span>一样',
                gif: './gif/null.png'
            },

            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '现在跳跃结束后会附带一次践踏效果<br>践踏宽度：384(3格)<br>践踏高度：96(0.75格)<br>践踏伤害：1.5<br>践踏晕眩：3.0秒<br>',
                gif: './gif/null.png'
            },

            {
                title: '<span class="j">JOKE</span>',
                description: '跳跃速度更快了，并且每次跳跃后都有50%的几率继续跳跃，最多跳跃10次。<br>跳跃刷新时刻：0.02 → 0.016<br>',
                gif: './gif/null.png'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: '和<span class="j">笑话难度</span>一样',
                gif: './gif/null.png'
            },
        ],
    },

    // 特殊技能 / 群体泡泡
    {
        Name: '特殊技能 / 群体泡泡',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="n">NORMAL</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="h">HARD</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="i">IMPOSSIBLE</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },
            {
                title: '<span class="j">JOKE</span>',
                description: '只有<span class="k">改造难度</span>及以上才会出现。',
                gif: './gif/null.png'
            },

            {
                title: '<span class="k">KAIZO</span>',
                description: '泡泡龙对场内所有存活玩家施加一个马上爆炸的透明泡泡，施法期间泡泡龙消失并且无敌。<br>在爆炸时间即将到达时，还会进行一次奥义判断，此时会直接中断技能。<br>爆炸时，每个透明泡泡都会生成一定数量的大泡泡，击中时造成伤害并且沉默。<br><br>爆炸时间：1.5(此时判断奥义)+0.5秒<br><br>大泡泡数量：6<br>弹幕碰撞大小：96(0.75格)<br>弹幕碰撞类型：无视地形|穿透型弹幕<br>弹幕重力：1.0<br>弹幕寿命：2.0<br>弹幕速度：30<br>弹幕击中沉默时间：15秒<br>弹幕伤害：2<br><br>释放后摇：0.5+1.0<br>',
                gif: './gif/Bobble/BossSkill-6-K.gif'
            },
        ],

    },

    // 终极技能 / 奥义
    {
        Name: '终极技能 / 奥义',
        skill: [
            {
                title: '<span class="e">EASY</span>',
                description: '暂时没有。',
                gif: './gif/Bobble/BossSkill-7.png'
            },
        ],

    },
]