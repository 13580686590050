<template>
  <!-- 排行榜 -->
  <div class="ranking" id="ranking">
    <div class="container">
      <!-- 标题 -->
      <h2 class="h2 section-title">玩家资产排行榜</h2>
      <!-- 总资产排行榜 -->
      <div class="total_assets">
        <table v-show="ranking" class="table table-striped table-hover">
          <!-- 标题 -->
          <thead class="table align-middle">
            <tr>
              <th>排名</th>
              <th>玩家名</th>
              <th>皮肤总价值</th>
              <th>皮肤数</th>
              <th>剩余币</th>
              <th>总资产</th>
              <th>保护次数</th>
              <th>重置次数</th>
            </tr>
          </thead>
          <!-- 内容 -->
          <tbody class="table align-middle">
            <tr v-for="(item, index) in assetsList" :key="index">
              <td>{{ (page - 1) * size + 1 + index }}</td>
              <td>{{ item.player_name }}</td>
              <td>{{ item.total_skin_value }}</td>
              <td>{{ item.skin_count }}</td>
              <td>{{ item.remaining_coins }}</td>
              <td>{{ item.total_coins }}</td>
              <td>{{ item.protect_count }}</td>
              <td>{{ item.update_reset_count }}</td>
            </tr>
          </tbody>
        </table>

        <!-- 图表 -->
        <div class="echarts" id="main" v-show="echarts"></div>
      </div>

      <!-- 排行榜底部 -->
      <div class="bottom">
        <!-- 分页器 -->
        <div class="pagin" v-show="show">
          <ul class="pagination">
            <!-- 上一页 -->
            <li
              class="page-item prev"
              style="cursor: pointer"
              @click.prevent="prev()"
            >
              <a class="page-link">上一页</a>
            </li>

            <!-- 第一页 -->
            <li
              class="page-item"
              @click.prevent="paging(1)"
              :class="{ active: page === 1 }"
            >
              <a class="page-link" href="#"> 1 </a>
            </li>
            <!-- 第二页 -->
            <li
              class="page-item"
              @click.prevent="paging(2)"
              :class="{ active: page === 2 }"
            >
              <a class="page-link" href="#"> 2 </a>
            </li>
            <!-- 第三页 -->
            <li
              class="page-item"
              @click.prevent="paging(3)"
              :class="{ active: page === 3 }"
            >
              <a class="page-link" href="#"> 3 </a>
            </li>
            <!-- 第四页 -->
            <li
              class="page-item"
              @click.prevent="paging(4)"
              :class="{ active: page === 4 }"
            >
              <a class="page-link" href="#"> 4 </a>
            </li>
            <!-- 第五页 -->
            <li
              class="page-item"
              @click.prevent="paging(5)"
              :class="{ active: page === 5 }"
            >
              <a class="page-link" href="#"> 5 </a>
            </li>
            <!-- 下一页 -->
            <li class="page-item next" @click.prevent="next()">
              <a class="page-link" href="#">下一页</a>
            </li>
          </ul>
        </div>

        <!-- 精确搜索 -->
        <div class="search" :class="{ active: searchFocus }">
          <input
            placeholder="请输入您的游戏全名 (不要编号) "
            v-model.trim="searchValue"
            @focus="searchFocus = true"
            @blur="searchFocus = false"
            @keydown.enter="search()"
          />
          <span class="search-btn-icon" @click="search()"
            ><i class="iconfont icon-sousuo1"></i
          ></span>
        </div>

        <!-- 显示图标 -->
        <button class="showEcharts btn btn-primary" @click="showEcharts()">
          切换图表 / 表格
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"; // 导入 axios
// import * as echarts from "echarts"; // 导入 图标插件

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入饼型图图表，图表后缀都为 Chart
import { PieChart } from "echarts/charts";
// 引入标题，提示框，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

export default {
  data() {
    return {
      // 排行榜数据
      assetsList: [],

      // 第几页
      page: 1,
      // 一页有几条数据
      size: 10,

      // 搜索框 获得焦点 失去焦点
      searchFocus: false,

      // 搜索的值
      searchValue: "",

      // 分页器显示与关闭
      show: true,

      // 图表
      echarts: false,

      // 排行榜
      ranking: true,
    };
  },

  // 创建阶段(准备数据)
  created() {
    // 渲染排行榜
    this.render();
  },

  //   挂载阶段(渲染模版)
  mounted() {},

  // 逻辑处理
  methods: {
    // 搜索
    search() {
      // 如果搜索框没有内容弹出提示
      if (!this.searchValue) {
        this.render();
      }
      // 请求搜索的数据
      axios({
        url: "https://moonglaive.com/api_v2/map/get_player_asset_info",
        method: "POST",
        data: {
          player_name: this.searchValue,
        },
      }).then((result) => {
        // 搜索的结果
        this.assetsList = result.data.data;
        this.page = 1;
        this.show = false;
        this.renderEcharts();
      });
    },

    // 上一页
    prev() {
      if (this.page > 1) {
        this.page--;
        this.render();
      } else {
        return;
      }
    },

    // 翻页
    paging(page) {
      this.page = page;
      this.render();
    },

    // 下一页
    next() {
      if (this.page < 5) {
        this.page++;
        this.render();
      } else {
        return;
      }
    },

    // 显示图表
    showEcharts() {
      this.echarts = !this.echarts;
      this.ranking = !this.ranking;
    },

    // 渲染图表
    renderEcharts() {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById("main");
      this.myChart = echarts.init(chartDom);

      // 初始化
      let option = {
        title: {
          text: "资产占比",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        color: [
          "#fc8251",
          "#5470c6",
          "#9A60B4",
          "#ef6567",
          "#9c9544",
          "#3BA272",
          "#91cd77",
          "#ffd966",
          "#00b0f0",
          "#ff0000",
        ],
        series: [
          {
            name: "剩余资产",
            type: "pie",
            radius: "50%",
            data: this.assetsList.map((item) => ({
              value: item.remaining_coins,
              name: item.player_name,
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    },

    // 渲染排行榜
    async render() {
      // 请求-排行榜-数据
      const result = await axios({
        url: "https://moonglaive.com/api_v2/map/asset_leaderboard",
        // 参数
        params: {
          page: this.page,
          size: this.size,
        },
      });
      this.assetsList = result.data.data;
      this.show = true;
      this.renderEcharts();
    },
  },
};
</script>

<style scoped>
/* 排行榜 */
.ranking {
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
}

/* 表格容器 */
.ranking .total_assets {
  position: relative;
  width: max-content;
  max-width: 100vw;
  overflow-x: auto;
  /* background: var(--raisin-black-1); */
  /* scroll-snap-type 设置了在有滚动容器的情形下吸附至吸附点的严格程度 */
  scroll-snap-type: inline mandatory;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.ranking .bottom {
  width: 700px;
  max-width: 100vw;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

/* 表格 */
.ranking .total_assets table {
  width: 700px;
  background-color: var(--light-gray);
  margin: 0;
}

/* 表格 */
.total_assets table th,
.total_assets table td {
  padding: 10px;
  height: 30px;
  scroll-snap-align: center;
}

/* 搜索框 */
.search {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 28px;
  padding: 8px 16px;
  background-color: #f2f2f2;
  border: 3px solid #f2f2f2;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* 搜索框 表单 */
.search input {
  width: 150px;
  height: 30px;
  padding: 10px;
  background-color: #f2f2f2;
}

/* 搜索框 按钮 */
.search button {
  width: 60px;
  height: 40px;
  /* border: 2px solid #7e7e7e; */
}

/* 搜索框 按钮 点击 */
.search button:active {
  border-right: 2px solid #929292;
  border-bottom: 2px solid #929292;
  border-top: 2px solid #1d1d1d;
  border-left: 2px solid #1d1d1d;
  background-color: #929292;
}

.search button:hover {
  background-color: #1c6cde;
}

/* 搜索栏 字体图标 */
.search .icon-sousuo1::before {
  width: 20px;
  height: 20px;
  font-size: 15x;
  color: #969696;
  cursor: pointer;
}

/* 切换类 */
.search.active {
  border: 3px solid #1c6cde;
}

/* 整个滚动条 */
.total_assets::-webkit-scrollbar,
.echarts::-webkit-scrollbar {
  height: 10px;
}
/* 滚动条的滚动滑块 */
.total_assets::-webkit-scrollbar-button,
.echarts::-webkit-scrollbar-button {
  width: 40px;
}

/* 图表 */
.echarts {
  width: 700px;
  height: 462px;
  padding: 20px;
  background-color: #f2f2f2;
  padding-bottom: 10px;
}

/* 分页器 */
.pagin {
  width: max-content;
  height: 30px;
  background: #f2f2f2;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: var(--transition-3);
  margin: 0 auto;
  margin-bottom: 20px;
}
/* 分页器 */
.pagin ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagin .page-item {
  width: 30px;
  height: 30px;
}
.pagin .page-link {
  width: 100%;
  color: #000000;
}
.pagin .prev,
.pagin .next {
  width: 60px;
  height: 30px;
}

.pagin .page-item:hover {
  background: #e7e7e7;
}

.pagin .prev:active,
.pagin .next:active {
  background: #1c6cde;
}

.pagin .active {
  background: var(--orange);
}

/* 切换图表按钮 */
.showEcharts {
  width: 180px;
  height: 50px;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 15px;
}

/* 响应大于760px --------------------------------------------------------------*/
@media (min-width: 760px) {
  /* 底部 */
  .ranking .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin: 0;
  }

  /* 显示图标按钮 */
  .ranking .showEcharts {
    margin: 0;
  }

  /* 搜索框 */
  .search {
    width: 300px;
    height: 40px;
    margin: 0;
  }

  /* 搜索框 表单 */
  .search input {
    width: 250px;
    height: 25px;
    font-size: 16px;
  }

  /* 分页器 */
  .pagin {
    height: 35px;
    line-height: 35px;
    margin: 0;
  }
  .pagin .page-item {
    width: 35px;
    height: 35px;
  }
  .pagin .prev,
  .pagin .next {
    width: 80px;
    height: 35px;
  }
}

/* 响应大于1024px */
@media (min-width: 1024px) {
  /* 底部 */
  .ranking .bottom {
    width: 850px;
  }
  /* 表格 */
  .ranking .total_assets table {
    width: 850px;
  }

  /* 图表 */
  .echarts {
    width: 850px;
  }
}

/* 响应大于1200px */
@media (min-width: 1200px) {
  /* 底部 */
  .ranking .bottom {
    width: 1000px;
  }
  /* 表格 */
  .ranking .total_assets table {
    width: 1000px;
  }

  /* 图表 */
  .echarts {
    width: 1000px;
  }
}
</style>

