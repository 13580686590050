var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boss",attrs:{"id":"BOSS"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"boss-left",class:{ active: _vm.active }},[_c('div',{staticClass:"boss-left-container"},[_c('div',{staticClass:"top",on:{"click":_vm.toggle}},[_c('i',{staticClass:"iconfont icon-xiangyou1"})]),_c('ul',{staticClass:"boss-left-nav"},[_c('li',{class:{ active: _vm.BossIndex === 1 },on:{"click":function($event){$event.preventDefault();return _vm.PacMan.apply(null, arguments)}}},[_vm._m(0)]),_c('li',{class:{ active: _vm.BossIndex === 2 },on:{"click":function($event){$event.preventDefault();return _vm.Bobble.apply(null, arguments)}}},[_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])]),_c('div',{staticClass:"boss-right"},[_c('div',{staticClass:"content"},[_c('h2',{staticClass:"h2 section-title"},[_vm._v("BOSS说明书")]),_c('div',{staticClass:"skill"},[_c('ul',_vm._l((_vm.BossData),function(item,index){return _c('li',{key:index,class:{ active: _vm.skillIndex === index },on:{"click":function($event){_vm.skillIndex = index}}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),0)]),_c('div',{staticClass:"skill-body"},[_c('div',{staticClass:"skill-explain"},[_c('div',{staticClass:"body"},[_c('h3',{domProps:{"innerHTML":_vm._s(
                  '当前难度描述：' + _vm.BossData[0].skill[_vm.difficulty].title
                )}}),_c('p',{staticClass:"h"},[_vm._v("温馨提示：点击右边图片可以切换难度描述文本！")]),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s(_vm.BossData[_vm.skillIndex].skill[_vm.difficulty].description)}})])]),_c('div',{staticClass:"skill-gif"},[_c('ul',_vm._l((_vm.BossData[_vm.skillIndex].skill),function(item,index){return _c('li',{key:index,on:{"click":function($event){_vm.difficulty = index}}},[_c('h4',{domProps:{"innerHTML":_vm._s(item.title)}}),_c('img',{attrs:{"src":item.gif,"alt":""}})])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss01.png"),"alt":""}}),_c('span',[_vm._v(" 吃豆人")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss02.png"),"alt":""}}),_c('span',[_vm._v(" 泡泡龙")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss03.png"),"alt":""}}),_c('span',[_vm._v(" 炸弹人")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss04.png"),"alt":""}}),_c('span',[_vm._v(" 库巴")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss05.png"),"alt":""}}),_c('span',[_vm._v(" 马戏团")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss06.png"),"alt":""}}),_c('span',[_vm._v(" 波波")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss07.png"),"alt":""}}),_c('span',[_vm._v(" 坦克")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss08.png"),"alt":""}}),_c('span',[_vm._v(" 梅塔")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss09.png"),"alt":""}}),_c('span',[_vm._v(" 气球战士")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss10.png"),"alt":""}}),_c('span',[_vm._v(" 快乐鼠")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/image/boss11.png"),"alt":""}}),_c('span',[_vm._v(" 星之卡比")])])])
}]

export { render, staticRenderFns }