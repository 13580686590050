<template>
  <div>
    <!-- 背景图 -->
    <!-- <div id="bg"></div> -->

    <!-- 头部区域 -->
    <VueHeader></VueHeader>

    <!-- 主体区域 -->
    <VueMain></VueMain>

    <!-- <playerRoom></playerRoom> -->

    <div class="test-01">
      <p>最后构建时间：{{ buildTime }}</p>
    </div>
  </div>
</template>

<script>
// 头部区域
import VueHeader from "./components/VueHeader.vue"; //  头部内容
import VueMain from "./components/VueMain.vue"; // 主体内容
// import playerRoom from "./views/playerRoom/index.vue"; // 游戏房间
export default {
  // 局部注册
  components: {
    VueHeader,
    VueMain,
    // playerRoom,
  },
  data() {
    return {
      buildTime: process.env.BUILD_TIME || "未知时间",
    };
  },
};
</script>

<style>
.test-01 {
  text-align: right;
  color: rgb(153, 153, 153);
}
/* 背景图片 */
/* #bg { */
/* position: absolute; */
/* top: 23.438vw; */
/* top: 0; */
/* width: 100%; */
/* height: 100%; */
/* background: url(./assets/image/bg.jpg); */
/* z-index: -999; */
/* } */
</style>
