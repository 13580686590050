<template>
  <!-- 头部 -->
  <header class="header">
    <!-- 导航按钮展开时 出现的遮罩背景 -->
    <div class="overlay" data-overlay></div>

    <!-- 容器 -->
    <div class="container">
      <!-- LOGO -->
      <a href="top" class="LOGO"
        ><img src="../assets/image/header-logo.png" alt="8bit logo" />&nbsp;8
        BIT RAID</a
      >

      <button class="nav-open-btn" data-nav-open-btn>
        <ion-icon name="menu-outline"></ion-icon>
      </button>

      <!-- 导航 -->
      <nav class="navbar" data-nav>
        <!-- 导航头部 -->
        <div class="navbar-top">
          <a href="#" class="LOGO"
            ><img src="../assets/image/header-logo.png" alt="8bit logo" />8 BIT
            RAID</a
          >

          <!-- 导航 关闭 按钮 -->
          <button class="nav-close-btn" data-nav-close-btn>
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        <!-- 导航列表 -->
        <ul class="navbar-list">
          <li><a href="#BOSS" class="navbar-link">BOSS介绍</a></li>
          <li><a href="#debuff" class="navbar-link">负面效果</a></li>
          <li><a href="#else" class="navbar-link">其他分类</a></li>
          <li><a href="#ranking" class="navbar-link">玩家资产排行榜</a></li>
          <li><a href="#famous" class="navbar-link">名场面</a></li>
          <!-- <li><a href="#null" class="navbar-link">null</a></li> -->
        </ul>

        <ul class="nav-social-list">
          <li>
            <a href="#" class="social-link">
              <!-- <ion-icon name="logo-discord"></ion-icon> -->
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <!-- <ion-icon name="logo-twitch"></ion-icon> -->
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <!-- <ion-icon name="logo-xbox"></ion-icon> -->
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <!-- <ion-icon name="logo-youtube"></ion-icon> -->
            </a>
          </li>
        </ul>
      </nav>

      <!-- 头部行为 -->
      <!-- <div class="header-actions"> -->
      <!-- 搜索 -->
      <!-- <button class="search"> -->
      <!-- <ion-icon name="search-outline"></ion-icon> -->
      <!-- </button> -->

      <!-- 登入? -->
      <!-- <button class="btn-sign_in">
          <div class="icon-box">
            <ion-icon name="log-in-outline"></ion-icon>
          </div>
          <span>Log-in</span>
        </button>
      </div> -->
    </div>
  </header>
</template>

<script>
// import _ from "lodash";
// import HeaderNavbar from "./HeaderNavbar.vue";
// import Navber from "./VueNavbar.vue"; // 导航栏组件
// import player from "./VuePlayer.vue"; // 音乐播放器
// import MyMenu from "./MyMenu.vue"; // 菜单栏

export default {
  components: {
    // HeaderNavbar,
    // Navber,
    // player,
    // MyMenu,
  },


  // 挂载阶段(渲染模版)
  mounted() {
    // 元素 切换 函数
    const elemToggleFn = function (Elem) {
      Elem.classList.toggle("active");
    };

    // 导航 变量
    const navbar = document.querySelector("[data-nav]"); // 导航
    const navOpenBtn = document.querySelector("[data-nav-open-btn]"); // 导航开启按钮
    const navCloseBtn = document.querySelector("[data-nav-close-btn]"); // 导航关闭按钮
    const overlay = document.querySelector("[data-overlay]"); // 覆盖

    // 导航元素数组
    const navElemArr = [navOpenBtn, navCloseBtn, overlay];

    // 遍历数组
    navElemArr.forEach((Elem) => {
      // 给每个元素添加点击事件
      Elem.addEventListener("click", () => {
        elemToggleFn(navbar);
        elemToggleFn(overlay);
        elemToggleFn(document.body);
      });
    });

    // // 回到首页按钮出现
    // const goTopBtn = document.querySelector("[data-go-top]");

    // function scrollFn() {
    //   if (window.scrollY >= 800) {
    //     goTopBtn.classList.add("active");
    //   } else {
    //     goTopBtn.classList.remove("active");
    //   }
    //   console.log(window.scrollY);
    // }

    // // 节流
    // window.addEventListener("scroll", _.throttle(scrollFn, 500));
  },
};
</script>

<style scoped>
/* 头部 */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--raisin-black-1);
  /* hsla() 函数使用色相、饱和度、亮度、透明度来定义颜色。 */
  box-shadow: 0px 3px 27px hsla(0, 0%, 0%, 0.5);
  padding-block: 5px;
  z-index: 4;
}
/* logo a 标签 */
.header .LOGO {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 43px;
  font-size: 26px;
  font-weight: var(--fw-700);
  font-family: "OPPOSans H";
  text-shadow: 3px 3px 3px #ff9700;
  color: #f7fc00;
}
/* logo 图片 img */
.header .LOGO img {
  width: 43px;
  height: 43px;
}
/* 容器 */
.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 头部行为 */
/* .header .header-actions {
  display: none;
} */

/* .header .icon-box {
  width: max-content;
} */

/* 导航 打开 按钮 */
.nav-open-btn {
  color: var(--white);
  font-size: 40px;
  padding: 5px;
}

/* 导航 */
.navbar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 100%;
  max-width: 300px;
  background-color: var(--raisin-black-2);
  height: 100%;
  box-shadow: 0 2px 8px hsla(0, 0%, 0, 0.5);
  visibility: hidden;
  z-index: 2;
  transition: var(--transition-2);
}

.navbar.active {
  right: 0;
  visibility: visible;
  transition: var(--transition-3);
}

/* 导航头部 */
.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}

/* 导航 关闭 按钮 */
.nav-close-btn {
  color: var(--white);
  font-size: 25px;
  padding: 10px;
  transform: translateX(15px);
}
.nav-close-btn:hover ion-icon {
  color: var(--orange);
}

.nav-close-btn ion-icon {
  --ionicon-stroke-width: 70px;
}

/* 导航列表 */
.navbar-list {
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  margin-bottom: 30px;
}

/* 导航 链接 */
.navbar-link {
  color: var(--white);
  font-size: 15px;
  padding: 10px 25px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  transition: var(--transition-1);
}

/* is() 是一个 CSS 伪类函数，该函数将选择器列表作为参数，并选择该列表中任意一个选择器可以选择的元素。 */
.navbar-link:is(:hover, :focus) {
  color: var(--orange);
}

.nav-social-list {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap 简写属性用于设置行与列之间的间隙（网格间距）。 */
  gap: 15px;
}

.social-link {
  color: var(--orange);
  font-size: 18px;
}

/* 遮罩层 */
.overlay {
  position: fixed;
  /* inset 为简写属性，对应于 top、right、bottom 和 left 属性 */
  inset: 0;
  /* pointer-events CSS 属性指定在什么情况下 (如果有) 某个特定的图形元素可以成为鼠标事件的 target */
  pointer-events: none;
  z-index: 1;
  transition: var(--transition-2);
}
/* 遮罩层显示 */
.overlay.active {
  background: hsla(0, 0%, 0%, 0.7);
  pointer-events: all;
  transition: var(--transition-3);
}

/* 响应大于1024px */
@media (min-width: 1024px) {
  .header {
    padding-block: 0;
  }

  .header .LOGO {
    margin-right: 25px;
  }

  .nav-open-btn {
    display: none;
  }

  .navbar-top,
  .nav-social-list {
    display: none;
  }

  .navbar {
    /* 指定元素使用正常的布局行为 */
    position: static;
    visibility: visible;
    background: none;
    max-width: unset;
    width: max-content;
    height: auto;
    box-shadow: none;
  }

  .navbar-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
  }

  .navbar-link {
    position: relative;
    color: var(--platinum);
    padding: 37px 25px;
    border: none;
    letter-spacing: 1px;
    z-index: 1;
    font-size: 16px;
  }

  .navbar-link::after {
    content: "";
    position: absolute;
    /* inset 为简写属性，对应于 top、right、bottom 和 left 属性 */
    inset: 0;
    /* skew() 函数定义了一个元素在二维平面上的倾斜转换 */
    transform: skew(150deg);
    z-index: -1;
    transition: var(--transition-1);
    border-bottom: 5px solid rgba(0, 0, 0, 0);
  }

  .navbar-link:hover::after {
    background: var(--raisin-black-2);
    border-color: var(--orange);
  }

  .overlay {
    display: none;
  }
}

/* 响应1200px */
@media (min-width: 1200pxpx) {
}
</style>
